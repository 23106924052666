"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileNavigation = void 0;
var MobileNavigation = /** @class */ (function () {
    function MobileNavigation() {
        this.$navAnchor = $('.toggleMobNav');
        this.$body = $('body');
        this.init();
    }
    MobileNavigation.prototype.init = function () {
        var _this = this;
        if (this.$navAnchor.length === 1) {
            var $headerImage_1 = $('.headerContainer .logoContainer .logo.visibleMobile img');
            var startImage_1 = $headerImage_1.attr('src');
            var basePath_1 = '/images/';
            var logoNameMobileWhite_1 = 'logo_mobile_white.svg';
            this.$navAnchor.click(function () {
                _this.$body.toggleClass('navOpen');
                if (_this.$body.hasClass('navOpen') && _this.$body.hasClass('mobile')) {
                    $headerImage_1.attr('src', basePath_1 + logoNameMobileWhite_1);
                }
                else {
                    $headerImage_1.attr('src', startImage_1);
                }
            });
        }
    };
    return MobileNavigation;
}());
exports.MobileNavigation = MobileNavigation;
