"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Functions = void 0;
// import 'jquery-migrate';
require("slick-carousel");
var navigation_1 = require("./modules/navigation");
var instagram_1 = require("./modules/instagram");
var popup_1 = require("./modules/popup");
var tab_1 = require("./modules/tab");
var Functions = /** @class */ (function () {
    function Functions() {
        this.$body = $("body");
        this.init();
    }
    Functions.prototype.init = function () {
        this.setDefaultProperties();
        // init header image
        this.initHeaderImage();
        // init mobile navigation
        var mobNav = new navigation_1.MobileNavigation();
        // init slider on home page
        this.initExhibitionSlider();
        // init on Exhibition and Artfair detail page
        this.initDetailPages();
        // init on Artist detail
        this.initArtistDetail();
        // check if page needs a call to the instagram API
        if (this.$body.hasClass("hasInstagramPhotos")) {
            var count = 1;
            if (this.$body.hasClass('shots')) {
                count = 20;
            }
            var instagram = new instagram_1.Instagram($('.loaderContainer'), $('.instagramContainer'), count);
        }
        this.initGrayBgArtistDetail();
        // general init (for each page)
        this.initGeneral();
        // this.initGoogleMaps();
        this.initContactDetail();
    };
    Functions.prototype.setDefaultProperties = function () {
        if ($(window).width() <= 768) {
            this.$body.addClass('mobile');
        }
        else {
            this.$body.addClass('desktop');
        }
    };
    Functions.prototype.isMobile = function () {
        return this.$body.hasClass("mobile");
    };
    Functions.prototype.isDesktop = function () {
        return this.$body.hasClass("desktop");
    };
    Functions.prototype.initGeneral = function () {
        var _this = this;
        $(document).ready(function () {
            $('.scrolledFromTop').on('click', function () {
                $('body, html').animate({ scrollTop: 0 }, 300);
            });
            $('form .formError').on('click', function (e) {
                $(e.currentTarget).fadeOut();
            });
            // push footer down when page is to small
            if (!_this.$body.hasClass('shots')) {
                if (_this.$body.height() < window.innerHeight) {
                    var dif = window.innerHeight - _this.$body.height();
                    var margin = parseInt($('.footerContainer').css('margin-top').split('px')[0]);
                    $('.footerContainer').css({ 'margin-top': (dif + margin) });
                }
            }
            // remove error class when user leaves the field
            $('.newsletterContainer input').blur(function (e) {
                $(e.target).removeClass('error');
            });
        });
    };
    Functions.prototype.initExhibitionSlider = function () {
        $('.exhibitionSlider').slick({
            arrows: false,
            autoplay: true,
            dots: false,
            infinite: true,
            variableWidth: false
        });
    };
    Functions.prototype.initDetailPages = function () {
        if (this.$body.hasClass('exhibitionDetail') ||
            this.$body.hasClass('artFairDetail') ||
            this.$body.hasClass('newsDetail')) {
            if (this.isMobile()) {
                if ($('.imageContainer img').length > 2) {
                    var $image = $(".imageContainer img:nth-child(2)");
                    var $pos = $(".contentContainer p:first-child");
                    $image.clone().insertAfter($pos);
                    $image.remove();
                }
            }
        }
    };
    Functions.prototype.initHeaderImage = function () {
        var basePath = "/images/";
        var logoName = "logo.svg";
        var logoNameWhite = "logo_white.svg";
        var logoNameMobile = "logo_mobile.svg";
        var logoNameMobileWhite = "logo_mobile_white.svg";
        if (this.$body.hasClass("portal") ||
            this.$body.hasClass("contact")) {
            $(".headerContainer .logoContainer .logo.hiddenMobile img").attr("src", basePath + logoNameWhite);
            $(".headerContainer .logoContainer .logo.visibleMobile img").attr("src", basePath + logoNameMobileWhite);
        }
        // mobile only
        if (this.isMobile()) {
            if (this.$body.hasClass('exhibitionDetail') ||
                this.$body.hasClass('artFairDetail') ||
                this.$body.hasClass('newsDetail')) {
                $(".headerContainer .logoContainer .logo.visibleMobile img").attr("src", basePath + logoNameMobileWhite);
            }
        }
    };
    Functions.prototype.initArtistDetail = function () {
        if (this.$body.hasClass('artistDetail')) {
            this.initArtistSliderAndPopup();
            this.initArtistExhibitionPager();
            this.initArtistMobile();
        }
    };
    Functions.prototype.initArtistSliderAndPopup = function () {
        var _this = this;
        var popupOpentBefore = false;
        var $slider = $('.artistSliderContainer');
        var $item = $('.artistDetail .artistSliderContainer img');
        var $popupEl = $('.popupWrapper');
        var $contentEl = $popupEl.find('.contentContainer');
        var popup = new popup_1.Popup($popupEl);
        var curIndex = 0;
        var nextIndex = 0;
        var prevIndex = 0;
        // setup slider
        $slider.on('init', function () {
            $item.on('click', function (e) {
                var $curImg = $(e.currentTarget);
                var $curSlick = $curImg.closest('.slick-slide');
                _this.artistNextPopupItem($curImg, $contentEl);
                curIndex = $curSlick.data('slick-index');
                nextIndex = $curSlick.next().data('slick-index');
                prevIndex = $curSlick.prev().data('slick-index');
                popup.open();
            });
        });
        $slider.slick({
            arrows: true,
            autoplay: false,
            dots: false,
            infinite: true,
            variableWidth: true,
            centerMode: false,
            lazyLoad: 'progressive',
            nextArrow: '<i class=\'fa fa-angle-right\'></i>',
            prevArrow: '<i class=\'fa fa-angle-left\'></i>',
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToScroll: 1,
                        centerMode: true,
                        lazyLoad: 'ondemand'
                    }
                }
            ]
        });
        // setup popup
        $popupEl.find('.closeContainer').on('click', function () {
            $slider.slick("slickGoTo", curIndex, true);
            popup.close();
        });
        $popupEl.find('.fa-angle-right, .fa-angle-left').on('click', function (e) {
            var $nextSlick;
            var $currentSlick = $slider.find(".slick-slide[data-slick-index=\"" + curIndex + "\"]");
            if ($(e.currentTarget).hasClass('fa-angle-right')) {
                $nextSlick = $slider.find(".slick-slide[data-slick-index=\"" + nextIndex + "\"]");
            }
            else {
                $nextSlick = $slider.find(".slick-slide[data-slick-index=\"" + prevIndex + "\"]");
            }
            nextIndex = $nextSlick.next().data('slick-index');
            prevIndex = $nextSlick.prev().data('slick-index');
            curIndex = $nextSlick.data('slick-index');
            var $childEl = $nextSlick.find('img');
            _this.artistNextPopupItem($childEl, $contentEl);
        });
        if (this.isMobile()) {
            $(window).on('load', function () {
                new tab_1.Tab($('.artistDetail .tabContainer'), ($('.artistContentWrapper').offset().top));
            });
        }
    };
    Functions.prototype.initArtistExhibitionPager = function () {
        var $parent = $('.artistExhibitions');
        var $childs = $parent.find('.itemContainer');
        var itemCount = $childs.length;
        var itemsPerPage = 5;
        var curPage = 0;
        var pages = Math.ceil(itemCount / 5);
        $parent.find(".itemContainer:nth-of-type(n+" + (itemsPerPage + 1) + ")").hide();
        for (var i = 0; i < pages; i++) {
            this.addPagerItem($('.contentContainer .pagerContainer'), (i + 1));
        }
        $('.contentContainer .pagerContainer .pageItem:first-child').addClass('active');
        $('.pagerContainer .pageItem').on('click', function (e) {
            var $curTarget = $(e.currentTarget);
            $('.pagerContainer .pageItem').removeClass('active');
            $curTarget.addClass('active');
            curPage = parseInt($curTarget.attr('data-index'));
            $parent.find('.itemContainer').hide();
            var start = curPage * itemsPerPage;
            for (var i = 0; i < itemsPerPage; i++) {
                $childs.eq(start + i).fadeIn();
            }
        });
        $childs.last().addClass('last');
    };
    Functions.prototype.addPagerItem = function ($parent, pageNumber) {
        var className = 'pageItem';
        var el = "<span class=\"" + className + "\" data-index=\"" + (pageNumber - 1) + "\">" + pageNumber + "</span>";
        $parent.append(el);
    };
    Functions.prototype.artistNextPopupItem = function ($clickedEl, $parentEl) {
        var $imgPh = $parentEl.find("img");
        var $imgPhDesc = $parentEl.find(".description");
        $imgPh.attr('src', $clickedEl.attr('data-src-large'));
        $imgPh.attr('alt', $clickedEl.attr('alt'));
        $imgPhDesc.text($clickedEl.attr('alt'));
    };
    Functions.prototype.initArtistMobile = function () {
        var _this = this;
        $(window).on('load', function () {
            var showAtHeight = $('.artistInfoAndTabs').offset().top;
            $(document).scroll(function () {
                var y = $(document).scrollTop();
                if (y > showAtHeight) {
                    _this.$body.addClass('showArtistInfo');
                }
                else {
                    _this.$body.removeClass('showArtistInfo');
                }
                // show scroll to top button
                if (y > 200) {
                    _this.$body.addClass('scrolledFromTop');
                }
                else {
                    _this.$body.removeClass('scrolledFromTop');
                }
            });
        });
    };
    Functions.prototype.initGrayBgArtistDetail = function () {
        var _this = this;
        if (!this.isDesktop())
            return;
        // TODO: hier stond een document ready om heen, is die nog nodig?
        if (this.$body.hasClass('artistDetail')) {
            this.calcStartPosLeft($('.artistContentWrapper .col-5'), $('.artistContentWrapper'), 'bgGray');
            $(window).on('resize', function () {
                _this.calcStartPosLeft($('.artistContentWrapper .col-5'), $('.artistContentWrapper'), 'bgGray');
            });
        }
    };
    Functions.prototype.calcStartPosLeft = function ($elOffset, $parent, childClass, addToOffset) {
        if (addToOffset === void 0) { addToOffset = 0; }
        var val = $elOffset.offset().left + addToOffset;
        $parent.find("." + childClass).css({ 'left': val }).attr('data-left', val);
    };
    Functions.prototype.initContactDetail = function () {
        var _this = this;
        if (this.$body.hasClass('contact')) {
            this.initGrayBgContact();
            if (this.isMobile()) {
                $(window).on('load', function () {
                    var showAtHeight = $('.contact .tabContainer').offset().top;
                    $(document).on('scroll', function () {
                        var y = $(document).scrollTop();
                        if (y > showAtHeight) {
                            _this.$body.addClass('fixedTabs');
                        }
                        else {
                            _this.$body.removeClass('fixedTabs');
                        }
                    });
                    var tab = new tab_1.Tab($('.contact .tabContainer'), $('.contact .mainContentWrapper').position().top);
                    if (window.location.hash) {
                        tab.showTab(2, true);
                    }
                });
            }
        }
    };
    Functions.prototype.initGrayBgContact = function () {
        var _this = this;
        if (!this.isDesktop())
            return;
        // TODO: hier stond een document ready omheen
        if (this.$body.hasClass('contact')) {
            this.calcStartPosLeft($('.mainContentWrapper .col-5'), $('.mainContentWrapper'), 'bgGray');
            $(window).on('resize', function () {
                _this.calcStartPosLeft($('.mainContentWrapper .col-5'), $('.mainContentWrapper'), 'bgGray');
            });
        }
    };
    Functions.prototype.initGoogleMaps = function () {
        var _this = this;
        if (this.isMobile())
            return;
        var $container = $('.googleMapsContainer');
        var containerEl = $('.googleMapsContainer')[0];
        this.positionGoogleMaps($container);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': $container.data('address')
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var map = new google.maps.Map(containerEl, {
                    zoom: 17,
                    center: results[0].geometry.location,
                    mapTypeControl: false,
                    styles: [
                        {
                            stylers: [
                                {
                                    saturation: -100
                                }
                            ]
                        },
                        {
                            featureType: "road",
                            elementType: "geometry",
                            stylers: [
                                {
                                    visibility: "simplyfied"
                                }
                            ]
                        }
                    ]
                });
                var marker = new google.maps.Marker({
                    position: results[0].geometry.location,
                    map: map
                });
            }
        });
        $(window).on('resize', function () {
            _this.positionGoogleMaps($container);
        });
    };
    Functions.prototype.positionGoogleMaps = function ($container) {
        if (!$container || $container.length < 1)
            return;
        this.calcStartPosLeft($('.mainContentWrapper .col-5'), $('.contactWrapper'), $container[0].className);
    };
    return Functions;
}());
exports.Functions = Functions;
window['fn'] = new Functions();
