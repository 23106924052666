"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tab = void 0;
var Tab = /** @class */ (function () {
    function Tab(tabParent, scrollTop) {
        this.childSelector = "li";
        this.curActiveIndex = 0;
        this.scrollTop = 0;
        this.$tabParent = tabParent;
        this.scrollTop = scrollTop;
        this.init();
    }
    Tab.prototype.init = function () {
        var _this = this;
        // show first tab
        var $firstElement = this.$tabParent.find(this.childSelector + ":first-child");
        this.showTab(this.getIndex($firstElement), false);
        this.$tabParent.find(this.childSelector).on('click', function (e) {
            _this.showTab(_this.getIndex($(e.currentTarget)));
        });
    };
    Tab.prototype.showTab = function (index, scroll) {
        var _this = this;
        if (scroll === void 0) { scroll = true; }
        this.closeOpenTabs();
        var $activeItem = $(".tabItem[data-tab-index=" + index + "]");
        var $activeLiItem = this.$tabParent.find(this.childSelector + "[data-tab-index=" + index + "]");
        $activeLiItem.addClass("active");
        $activeItem.removeClass("hidden");
        if (scroll) {
            $activeItem.fadeIn(300, null, function () {
                $('html, body').animate({ scrollTop: _this.scrollTop }, 300);
            });
        }
    };
    Tab.prototype.closeOpenTabs = function () {
        $(".tabItem").addClass("hidden");
        this.$tabParent.find("" + this.childSelector).removeClass("active");
    };
    Tab.prototype.getIndex = function ($el) {
        return parseInt($el.data("tab-index"));
    };
    return Tab;
}());
exports.Tab = Tab;
