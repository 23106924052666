"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Instagram = void 0;
var Instagram = /** @class */ (function () {
    function Instagram($loaderContainer, $container, count) {
        if (count === void 0) { count = 1; }
        this.count = 0;
        this.count = count;
        this.init($loaderContainer, $container);
    }
    Instagram.prototype.init = function ($loaderContainer, $container) {
        if ($loaderContainer === void 0) { $loaderContainer = null; }
        if ($container === void 0) { $container = $('.instagramContainer'); }
        if ($container instanceof jQuery == false)
            return;
        if ($loaderContainer != null && $loaderContainer instanceof jQuery) {
            $loaderContainer.fadeIn();
        }
        $.ajax({
            url: 'ajax/get_instagram_images.ajax.php',
            type: 'POST',
            data: "count=" + this.count,
            success: function (data) {
                if (data) {
                    var photos = JSON.parse(data);
                    if (photos && photos.length > 0) {
                        $container.hide();
                        var _loop_1 = function (i) {
                            var $wrapper = $(document.createElement('div'));
                            $wrapper.addClass('itemContainer');
                            var anchor = document.createElement('a');
                            anchor.target = '_blank';
                            anchor.href = photos[i].link;
                            var img = document.createElement('img');
                            img.src = photos[i].url;
                            img.onload = function () {
                                if (img.width > img.height) {
                                    $wrapper.addClass('wide');
                                }
                                else {
                                    $wrapper.addClass('tall');
                                }
                            };
                            anchor.appendChild(img);
                            $wrapper.append(anchor);
                            $wrapper.append("<span class=\"date\">" + photos[i].date + "</span>");
                            $container.append($wrapper);
                        };
                        for (var i = 0; i < photos.length; i++) {
                            _loop_1(i);
                        }
                        $loaderContainer.fadeOut(300, function () {
                            $container.fadeIn();
                        });
                    }
                }
            }
        });
    };
    return Instagram;
}());
exports.Instagram = Instagram;
