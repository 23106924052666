"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popup = void 0;
var Popup = /** @class */ (function () {
    function Popup(popupEl) {
        this.$body = $('body');
        this.$popupEl = popupEl;
        this.init();
    }
    Popup.prototype.init = function () { };
    Popup.prototype.open = function () {
        this.$popupEl.addClass('open');
        this.$body.addClass('popupOpen');
    };
    Popup.prototype.close = function () {
        this.$popupEl.removeClass('open');
        this.$body.removeClass('popupOpen');
    };
    return Popup;
}());
exports.Popup = Popup;
